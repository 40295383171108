import React, {useState, useEffect} from 'react';
import * as XLSX from 'xlsx';
import { Post } from '../Tools/Helper';
import StatusBar from '../Tools/StatusBar';
import ExcelImg from '../excel.png';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faFileArrowUp, faEraser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from "react-router-dom";

function Excel2({setLoading, capturista}){

	library.add(faDownload,faFileArrowUp,faEraser);

	const [filteredJson, setFilteredJson] = useState(null);
	const [status, setStatus] = useState({status: null, message: ""});
	const [inputKey, setInputKey] = useState();

	const [arrF, setArrayFinal] = useState();

	const handleFile = (event) => {

		setStatus({status: null, message: ""});
	   	var extension = event.target.files[0].type;

		if(extension !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
			setStatus({status: 'danger', message:'La extension del archivo no coincide con una hoja de calculo Excel.'});
			event.target.value = null;
			return;
		}

		const reader = new FileReader();
		reader.readAsArrayBuffer(event.target.files[0]);
		setLoading(true);

		reader.onloadend = (e) => {
			setLoading(false);
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, {type: 'array', cellDates: 'true'});
			const worksheet = workbook.Sheets[workbook.SheetNames[0]];

			const json = XLSX.utils.sheet_to_json(worksheet);
			//let filtered = json.map(({NOMBRE,TELEFONO}) => ({NOMBRE,TELEFONO}));
			setFilteredJson(json);
		}
	}

	/*
	useEffect(() => { 

	}, [data]);*/

	function clearFile() {
		let randomString = Math.random().toString(10);
		setInputKey(randomString);
		setStatus({status: null, message: ""});
		setFilteredJson(null);
	}

	const submitFile = async e =>{
    
    setStatus({status: null, msg: ""});
    setLoading(true);

    let filteredData = filteredJson.map( function(value) { 
        value['capturista'] = capturista;
        value['id_lista'] = 1;
        return value; 
    });

    const submit = await Post('insertar-padron',filteredData);

    if(submit.status.status === "success"){
      clearFile();
    }

    setStatus(submit.status);
    setLoading(false);
  }

  /*
	const miembros = filteredJson != null ? filteredJson.map((miembro, index) => {
		return(
			<div className="col-12 col-lg-6">
				<div className={"card my-3 " + miembro.tipo_vinculo}>
					<div className={"d-flex card-header my-0 py-1"}>
						<h5 className="text-uppercase flex-fill pt-2">{miembro.nombre} {miembro.apellido_paterno} {miembro.apellido_materno}</h5>
						<h5 className="align-self-end fw-bold pt-2">{miembro.tipo_vinculo}</h5>
					</div>
					
					<div className="card-body px-2 py-1 text-center text-lg-start">
						<ul class="list-group list-group-flush m-1 m-0">
							<li class="list-group-item p-1">
								<div className="row">
									<div className="col-4 col-lg-4">
										<span className="fw-bold">Factura</span><br /> {miembro.numero_factura}
									</div>
									<div className="col-4 col-lg-4">
										<span className="fw-bold">Fecha</span><br /> {miembro.fecha.toString()}
									</div>
									<div className="col-4 col-lg-4">
										<span className="fw-bold">Cliente</span><br /> {miembro.cliente}
									</div>
									<div className="col-12 col-lg-4">
										<span className="fw-bold">Total</span><br /> {miembro.total}
									</div>
									<div className="col-6 col-lg-4">
										<span className="fw-bold">Vendedor</span><br/> {miembro.vendedor}
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		)
	}) : "";*/

	function saveArray(){
		let arrayFinal = [];
		for(let i=0; i<filteredJson.length; i++){
			arrayFinal.push({name: filteredJson[i].Nombre + " " + 
				filteredJson[i].Apellido_paterno + " " + filteredJson[i].Apellido_materno,
				email: filteredJson[i].Matricula,
				password: filteredJson[i].Matricula,
				matricula: filteredJson[i].Matricula,
				tipo_usuario_id: 2,
				verified: 0,
				admin: false,
				status:0,
				});
		}
		setArrayFinal(arrayFinal);
	}
	
	return(
   		<div>
	      <div className="row">
        	<div className="col-12 col-lg-6">
						<h3 className="py-1"><span className="fw-bold">Subir</span> formato de miembros</h3>
		        <div className="card">
		            <div className="card-body">
			            <div className="mb-3">
							<label for="formFile" className="form-label">Seleccione el archivo de excel con los datos de los miembros a agregar</label>
							<input className="form-control" type="file" id="formFile" onChange={handleFile}
								key={inputKey || '' } />
							{ filteredJson !== null ? <p className="pt-3">El archivo seleccionado contiene <span className="fw-bold">{filteredJson.length}</span> registros de miembros para añadir.</p>: "" }
						</div>
						<div className="row">
							<div className="col-6">
								<button onClick={clearFile} className="btn btn-danger w-100"><FontAwesomeIcon icon="fa-eraser" className="pe-3" />Limpiar</button>
							</div>
							<div className="col-6">
								<button onClick={submitFile} className="btn btn-primary w-100"><FontAwesomeIcon icon="fa-file-arrow-up" className="pe-3" />Subir</button>
							</div>
						</div>
		            </div>
		        </div>
			    </div>
	        <div className="col-12 col-lg-6">
			    	<h3 className="py-3"><span className="fw-bold">Bajar</span> formato de miembros</h3>
			        <div className="card">
		            <div className="card-body">
			            <div className="row">
										<div className="col-3">
											<img src={ExcelImg} className="img-fluid"/>
										</div>
										<div className="col-9 align-self-center">
											<p>Descarga el formato de Excel para subir miembros al padrón.</p>
											<Link to="/formato_padron.xlsx" target="_blank" download className="btn btn-success w-50 mx-auto d-block "><FontAwesomeIcon icon="fa-download" className="pe-3" />Descargar </Link>
											{//<button onClick={logJson} className="btn btn-success w-50 mx-auto d-block ">sadsadsa</button>
											}
										</div>
					        </div>
		          	</div>
		        	</div>
			   	</div>
			   	<div className="col-12 pt-4">
			   		<StatusBar status={status} />
			   	</div>
	      </div>
				<div className="row pt-4">
					<div className="col-12">
						{filteredJson != null ? <h3 className="pb-1 fw-bold">Lista de miembros</h3> : ""}
					</div>
						{/*miembros*/}
		      </div>
				</div>
	)
}

export default Excel2;