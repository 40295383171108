import React, {useEffect, useState} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  BarElement
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { Post, Get } from '../Tools/Helper';

import Select from 'react-select';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

function DashboardUsuario({setLoading, capturista}){

	const [municipios, setMunicipios] = useState([]);
	const [status, setStatus] = useState({status: null, msg: ""});
	const [selectedMun, setSelectedMun] = useState([]);
	const [data, setData] = useState([]);

	useEffect(()=>{

	  const getCatalogos = async (index) =>{
	    
			setStatus({status: null, message: ""});
			setLoading(true);

			const municipios = await Get('lista-municipios');
			if(municipios.status.status === "success"){
				setMunicipios(municipios.municipios/*data*/);
			}else{
				setStatus(municipios.status);
			}

			setLoading(false);
		}

		getCatalogos();

	},[]);

	useEffect(()=>{

	  const getDash = async (index) =>{
	    
			setStatus({status: null, message: ""});
			setLoading(true);

			const dash = await Post('dashboard-usuario',{municipio:selectedMun.nombre_municipio, capturista:capturista});
			if(dash.status.status === "success"){
				setData(dash.data);
			}else{
				setStatus(dash.status);
			}

			setLoading(false);
		}

		getDash();

	},[selectedMun]);

	const handleChange = selectedOption => {
    	setSelectedMun(selectedOption);
  	};

  	const columns = [
		{
	        name: 'Num Seccion',
	        selector: row => row.seccion,
	        cellExport: row => row.seccion,
	        sortable: true
	    },
	    {
	        name: 'Comites',
	        selector: row => row.comites,
	        cellExport: row => row.comites,
	        sortable: true
	    },
	    {
	        name: 'Registros totales',
	        selector: row => row.total,
	        cellExport: row => row.total,
	        wrap: true,
	        sortable: true
	    },
	];

	const tableData = {
	    columns,
	    data,
	};

	return (<>

		<div className="container">
			<div className="row">
				<div className="col-12 pt-4">
					<h5>Seleccione un municipio</h5>
					<Select 
										className="z-10"
	                  options={municipios}
	                  getOptionValue={(option) => `${option['municipio_id']}`}
	                  getOptionLabel={(option) => `${option['nombre_municipio']}`}
	                  onChange={handleChange}
	                  value={selectedMun}
	                  placeholder="Seleccione Municipio..."
	                />
	                <div className="py-4">
	                <DataTableExtensions {...tableData}
	        			export={true}
	        			print={false}
	        			exportHeaders
				        fileName={"Reporte"}
				        filterPlaceholder="Buscar..." >
			        	<DataTable
				            columns={columns}
				            data={data}
				            fixedHeader
				            pagination
				            defaultSortFieldId={1}
				            paginationComponentOptions={{
		                        rowsPerPageText: 'Secciones por página:', 
		                        rangeSeparatorText: 'de', 
		                        noRowsPerPage: false, 
		                        selectAllRowsItem: true, 
		                        selectAllRowsItemText: 'Todos'
		                      }}
		                //noDataComponent=<div className="p-4">No se han encontrado registros.</div>
				        />  
			        </DataTableExtensions>
			        </div>
				</div>
			</div>
			{/*<Pie data={data} />*/}
		</div>
		</>);
}

export default DashboardUsuario;