import React, {useState, useEffect, useMemo} from 'react';
import { Put, Get, Post, Del } from '../Tools/Helper';
import StatusBar from '../Tools/StatusBar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCloudArrowUp, faTrashCan, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import Select from 'react-select';

function Padron({setLoading, capturista}){

  library.add(faCloudArrowUp, faTrashCan, faMagnifyingGlass);

  const [status, setStatus] = useState({status: null, msg: ""});

  const emptyForm =  { 
    distrito_local:'',
    distrito_federal:'',
    municipio:'',
    seccion:'',
    apellido_paterno: '',
    apellido_materno: '',
    nombre: '',
    calle: '',
    num_ext: '',
    num_int: '',
    colonia: '', 
    estado: 'TABASCO',
    cp: '',
    edad: '',
    clave_elector: '',
    correo_personal: '',
    celular: '',
    facebook: '',
    tipo_vinculo: 'PROMOTOR',
    vencimiento: '2021',
    comite: '01',
    id_lista: 1,
  };

  const [coordinador, setCoordinador] = useState("");
  const [seccion, setSeccion] = useState(null);

  const [distritoLocal, setDistritoLocal] = useState([]);
  const [distritoFederal, setDistritoFederal] = useState([]);
  const [comites, setComites] = useState(null);
  const [secciones, setSecciones] = useState(null);
  const [municipios, setMunicipios] = useState([]);
  const [found, setFound] = useState(false);

  const [borrarShow, setBorrarShow] = useState(false);

  const [id, setId] = useState('');

  const estados = ["TABASCO"];
  const years = ["2021","2022","2023","2024","2025","2026","2027","2028","2029","2030"];
  const mayus = /[^A-z\s]/;
  const numeric = /[^0-9]/;

  const [formData, setFormData] = useState([emptyForm]);

  useEffect(()=>{

    const getCatalogos = async e =>{
    
      setStatus({status: null, message: ""});
      setLoading(true);
      const catalogos = await Get('catalogos');

      if(catalogos.status.status === "success"){
        setStatus({status: null, message: ""});
        setDistritoLocal(catalogos.local);
        setDistritoFederal(catalogos.federal);
        setComites(catalogos.comites);
        setMunicipios(catalogos.municipios);
        setSecciones(catalogos.secciones);

      }else{
        setStatus(catalogos.status);
      }
      setLoading(false);
    }

    getCatalogos();

  },[setLoading]);


  function MapList({list, keyName, value}){
    return list.map((item) =>
    <option key={item[keyName]} value={item[value]}>{item[value]}</option>);
  }

  function SimpleMapList({list}){
    return list.map((item) =>
    <option key={item} value={item}>{item}</option>);
  }

  function MapListSeccion(){
    return secciones.map((item,index) =>
    <option key={index} value={index}>{item.num_seccion}</option>);
  }

  const handleFormChange = (event, expression = null) => {
    let tempData = {...formData};
    let val = "";
    expression !== null ? val = event.target.value.replace(expression, '') : val = event.target.value;
    tempData[event.target.name] = val.toUpperCase();
    setFormData(tempData);
  }

  const handleChangeTipo = (event) =>{
	setFormData((formData)=> ({...formData, [event.target.name]:event.target.value}));
  }

  const submitPadron = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const submit = await Put('padron-editar/'+formData.padron_id,formData);

    if(submit.status === "success"){
      setFormData([emptyForm]);
      setId('');
      setFound(false);
    }

    setStatus(submit);
    setLoading(false);
  }

  const buscar = async e =>{
    
      setStatus({status: null, message: ""});
      setLoading(true);
      const data = await Post('padron-buscar/'+id,{capturista:capturista});

      if(data.status === "success"){
        setFormData(data.datos);
        setComites(data.comites);
        setFound(true);
      }else if(data.status === "not_found"){
        setStatus({status:"danger", message:"No se ha encontrado un registro con ese ID."});
        setFormData([emptyForm]);
        setFound(false);
      }else if(data.status === "error"){
        setStatus({status:"danger", message:"El registro a editar no corresponde con el capturista."});
        setFormData([emptyForm]);
        setFound(false);
      }
      setBorrarShow(false);
      setLoading(false);
    }

    const borrarPadron = async e =>{
    
      if(window.confirm("¿Seguro que desea borrar el registro?")){
        setStatus({status: null, message: ""});
        setLoading(true);
        const data = await Del('padron/'+formData.padron_id);

        if(data.status === "success"){
          setFormData([]);
          setId('');
          setFound(false);
          setStatus({status: 'success', message: "Se ha borrado el registro correctamente."});
        }
        setBorrarShow(false);
        setLoading(false);
      }
    }


    const test = async e =>{
        setFormData([]);
        setComites([]);
        setId('');
        setFound(false);
        setStatus({status: 'success', message: "Se ha borrado el registro correctamente."});
    }



  const form = <div className="col-12 py-2">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className={"col-6 col-lg-2 mb-3"}>
                <label htmlFor="tipo_vinculo" className="form-label">Tipo<span className="req">*</span></label>
                <select name="tipo_vinculo" className="form-select" onChange={event => handleChangeTipo(event)} value={formData.tipo_vinculo}>
                  <option key={"PROMOTOR"} id={1} value="PROMOTOR">PROMOTOR</option>
                </select>
              </div>
              <div className="col-6 col-lg-2 mb-3">
                <label htmlFor="distrito_federal" className="form-label">Distrito Fed.<span className="req">*</span></label>
               <select name="distrito_federal" className="form-select" onChange={event => handleChangeTipo(event)} value={formData.distrito_federal}>
                  <MapList keyName="id_distrito_federal" value="num" list={distritoFederal} />
                </select>
              </div>
              <div className="col-6 col-lg-2 mb-3">
                <label htmlFor="distrito_local" className="form-label">Distrito Loc.<span className="req">*</span></label>
                <select name="distrito_local" className="form-select" onChange={event => handleChangeTipo(event)} value={formData.distrito_local}>
                  <MapList keyName="id_distrito_local" value="num" list={distritoLocal} />
                </select>
              </div>
              <div className="col-2 col-lg-2 mb-3">
                <label htmlFor="comite" className="form-label">Comite<span className="req">*</span></label>
                  {comites ? 
                    <select name="comite" className="form-select" onChange={event => handleChangeTipo(event)} value={formData.comite}>
                      <MapList keyName="id_comite" value="num" list={comites} />
                    </select> : 
                    <select name="distrito_federal" className="form-select" disabled>
                      <option key="0" value={null}>...</option>
                    </select>}
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-3">
                <label htmlFor="apellido_paterno" className="form-label">Apellido Paterno<span className="req">*</span></label>
                <input type="text" name="apellido_paterno" 
                  placeholder="Apellido Paterno" className="form-control"
                  value={formData.apellido_paterno} onChange={event => handleFormChange(event, mayus)} />
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-3">
                <label htmlFor="apellido_materno" className="form-label">Apellido Materno<span className="req">*</span></label>
                <input type="text" name="apellido_materno" 
                  placeholder="Apellido Materno" className="form-control"
                  value={formData.apellido_materno} onChange={event => handleFormChange(event, mayus)} />
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-3">
                <label htmlFor="nombre" className="form-label">Nombre<span className="req">*</span></label>
                <input type="text" name="nombre" 
                  placeholder="Nombre" className="form-control"
                  value={formData.nombre} onChange={event => handleFormChange(event, mayus)} />
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-3">
	              <label htmlFor="calle" className="form-label">Calle</label>
	              <input type="text" name="calle" 
	                placeholder="Calle" className="form-control"
	                value={formData.calle} onChange={event => handleFormChange(event)} />
	            </div>
              <div className="col-3 col-lg-1 mb-3">
                  <label htmlFor="num_ext" className="form-label">Num Ext</label>
                  <input type="text" name="num_ext" 
                    placeholder="Ext" className="form-control"
                    value={formData.num_ext} onChange={event => handleFormChange(event, numeric)}
                    size="5" maxLength="5" />
                </div>
                <div className="col-3 col-lg-1 mb-3">
                  <label htmlFor="num_int" className="form-label">Num Int</label>
                  <input type="text" name="num_int" 
                    placeholder="Int" className="form-control"
                    value={formData.num_int} onChange={event => handleFormChange(event, numeric)}
                    size="5" maxLength="5" />
                </div>
              <div className="col-6 col-lg-2 mb-3">
                <label htmlFor="colonia" className="form-label">Localidad<span className="req">*</span></label>
                <input type="text" name="colonia" 
                  placeholder="Localidad" className="form-control"
                  value={formData.colonia} onChange={event => handleFormChange(event)} />
              </div>
              <div className="col-6 col-lg-2 mb-3">
                <label htmlFor="municipio" className="form-label">Municipio<span className="req">*</span></label>
                {seccion === null ? <select name="municipio" className="form-select" onChange={event => handleChangeTipo(event)} value={formData.municipio}>
                  <MapList keyName="municipio_id" value="nombre_municipio" list={municipios} />
                </select> : <input type="text" name="municipio" className="form-control"
                value={formData.municipio} disabled />}
              </div>
              <div className="col-6 col-md-4 col-lg-2 mb-3">
                <label htmlFor="estado" className="form-label">Estado<span className="req">*</span></label>
                <select name="estado" className="form-select" onChange={event => handleChangeTipo(event)} value={formData.estado}>
                  <SimpleMapList list={estados} />
                </select>
              </div>
              <div className="col-6 col-md-4 col-lg-1 mb-3">
                  <label htmlFor="cp" className="form-label">CP</label>
                  <input type="text" name="cp" 
                    placeholder="CP" className="form-control"
                    value={formData.cp} onChange={event => handleFormChange(event, numeric)}
                    size="7" maxLength="7" />
                </div>
              <div className="col-6 col-md-4 col-lg-1 mb-3">
                <label htmlFor="edad" className="form-label">Edad</label>
                <input type="text" name="edad" 
                  placeholder="Edad" className="form-control"
                  value={formData.edad} onChange={event => handleFormChange(event, numeric)}
                  size="2" maxLength="2" />
              </div>
              <div className="col-lg-3 mb-3">
                <label htmlFor="clave_elector" className="form-label">Clave de elector<span className="req">*</span></label>
                <input type="text" name="clave_elector" 
                  placeholder="Clave Elector" className="form-control"
                  value={formData.clave_elector} onChange={event => handleFormChange(event)}
                  size="18" maxLength="18" />
              </div>
              <div className="col-6 col-md-4 col-lg-2 mb-3">
                <label htmlFor="vencimiento" className="form-label">Año Vencimiento<span className="req">*</span></label>
                <select name="vencimiento" className="form-select" onChange={event => handleFormChange(event,numeric)} value={formData.vencimiento}>
                  <SimpleMapList list={years} />
                </select>
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-3">
	              <label htmlFor="correo_personal" className="form-label">Correo</label>
	              <input type="text" name="correo_personal" 
	                placeholder="Correo" className="form-control"
	                value={formData.correo_personal} onChange={event => handleFormChange(event)} />
	          </div>
              <div className="col-6 col-md-4 col-lg-3 mb-3">
                <label htmlFor="celular" className="form-label">Telefono<span className="req">*</span></label>
                <input type="text" name="celular" 
                  placeholder="Telefono" className="form-control"
                  value={formData.celular} onChange={event => handleFormChange(event,numeric)}
                  size="10" maxLength="10" />
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-3">
	              <label htmlFor="facebook" className="form-label">Facebook</label>
	              <input type="text" name="facebook" 
	                placeholder="Facebook" className="form-control"
	                value={formData.facebook} onChange={event => handleFormChange(event)} />
	            </div>
              <div className="col-6 col-md-4 col-lg-3 mb-3 mt-auto">
                <small className="mt-auto">Los campos marcados con <span className="req">*</span> son <span className="fw-bold">obligatorios</span></small>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-6 text-center">
          <button className="btn btn-danger btn-lg px-5 mt-4 w-50" onClick={borrarPadron}>
            <FontAwesomeIcon icon="fa-solid fa-trash-can" className="pe-2" />BORRAR
            </button> 
        </div>
        <div className="col-6 text-center">
             <button className="btn btn-primary btn-lg px-5 mt-4 w-50" onClick={submitPadron}><FontAwesomeIcon icon="fa-solid fa-cloud-arrow-up" className="pe-2" />Editar</button>
        </div>
        </div>
      </div>;

	return(
		secciones !== null ? <>
          <div className="row">
            <div className="col-12">
              <h3 className="mb-4"><span className="fw-bold">Editar</span> registro</h3>
            </div>
            <div className="col-6 col-lg-4 mb-3">
              <div className="input-group mb-3">
                <span className="input-group-text" id="numero-miembros">Buscar por ID:</span>
                <input type="number" className="form-control" aria-label="Buscar por ID:" 
                  aria-describedby="numero-miembros" value={id} onChange={(e)=>{setId(parseInt(e.target.value))}} />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="input-group mb-3">
                <button className="btn btn-primary w-100" onClick={buscar}><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className="pe-2" />Buscar</button>
              </div>
            </div>
            {/*
            	<div className="col-12 col-md-6 col-lg-4">
                          <div className="input-group mb-3">
                            <label className="input-group-text" htmlFor="seccion">Seccion<span className="req">*</span></label>
                            {/*
                              <select className="form-select" name="seccion" id="seccion"
                                onChange={event => handleChangeSeccion(event)} value={seccion}>
                                <option key="0" value="">Seleccione...</option>
                                <MapListSeccion />
                              </select>
                            }
                            <Select 
                              options={secciones}
                              getOptionValue={(option) => `${option['seccion_id']}`}
                              getOptionLabel={(option) => `${option['num_seccion']}`}
                              onChange={handleChangeTipo}
                              value={seccion}
                              placeholder="Seleccione..."
                            />
                          </div>
                        </div>
                */
                }
          </div>
          <div className="row">
            <StatusBar status={status} />
            {found ? form : "" }
          </div>
          {
            //<a className="btn btn-primary btn-lg" onClick={()=>console.log(emptyForm)}><FontAwesomeIcon icon="fa-solid fa-cloud-arrow-up" className="pe-2" /> Sadsadsadsadmbros</a>

              
          }
        </> : "Cargando..." 
	);
}

export default Padron;