//const base= 'https://golsbita.golsystems.com/api/';
const base = 'https://gestion-electoral.golsystems.com/botapi/public/api/';
//const base= 'http://localhost:8000/api/';
const token = JSON.parse(localStorage.getItem('tokens')) ? JSON.parse(localStorage.getItem('tokens')).access_token : '';

export async function Post(path,obj){

	let isSubscribed = true;

	try{
		const requestOptions = {
			method: 'POST',
			headers: { 
				'Authorization': "Bearer " + token,
				'Content-Type': 'application/json' 
			},
			body: JSON.stringify(obj)
		};

		console.log("POST: " + path);
		console.log("DATA: ");
		console.log(obj);

		let response = await fetch(base+path, requestOptions);

		if (isSubscribed) {
			let data = await response.json();
			console.log("RESPONSE:");
			console.log(data);
			return data;
		}

	}catch(err){
		return {status:'error', message: err};
	}

	return () => {isSubscribed = false};

}

export async function Get(path){

	let isSubscribed = true;

	try{
		
		const requestOptions = {
			method: 'GET',
			headers: { 
				'Authorization': "Bearer " + token,
				'Content-Type': 'application/json' 
			}
		};

		console.log("POST: " + path);

		let response = await fetch(base+path, requestOptions);

		if (isSubscribed) {
			let data = await response.json();
			console.log("RESPONSE:");
			console.log(data);
			return data;
		}

	}catch(err){
		return {status:'error', message: err};
	}

	return () => {isSubscribed = false};

}