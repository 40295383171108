import logo from './logo.svg';
import './App.css';
import './style.css';
import {useState} from 'react';

import Navbar from './Template/Navbar';
import Sidebar from './Template/Sidebar';
import Loading from './Tools/Loading';

import Excel from './Admin/Excel';
import Excel2 from './Admin/Excel2';
import Padron from './Admin/Padron';
import Index from './Admin/Index';
import Lista from './Admin/Lista';
import Test from './Admin/Test';
import Editar from './Admin/Editar';
import DashboardUsuario from './Admin/DashboardUsuario';

import ListaDifusion from './Listas/ListaDifusion';


import Login from './Login/Login';
import Logout from './Login/Logout';

import { useNavigate  } from "react-router-dom";

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { AuthContext } from "./context/auth";
import PrivateRoute from './PrivateRoute';

import { library } from '@fortawesome/fontawesome-svg-core';

function App(props) {

  const [authTokens, setAuthTokens] = useState(localStorage.getItem('tokens') || '');

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData")));

  //var dataa = JSON.parse(userData);

  const setTokens = (data) => {
    localStorage.setItem('tokens', JSON.stringify(data));
    setAuthTokens(data);
  }

  const deleteTokens = () =>{
    localStorage.removeItem('tokens');
    setAuthTokens('');
  }

  let navigate = useNavigate();

  const loginHandler = (data) =>{
    localStorage.setItem("userData", JSON.stringify(data));
    setUserData(data);
    /*if(data.tipo_usuario_id === "2"){
      return navigate("/agregar-padron");
    }*/
    return navigate("/");
  }

  const logoutHandler = () => {
    localStorage.clear();
    setUserData(null);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, "700")
    return navigate("/login");
  };

  return (
    <>
    <AuthContext.Provider value={{authTokens: authTokens, setAuthTokens: setTokens, deleteTokens: deleteTokens }}>
      <Navbar data={userData} />
      <Sidebar data={userData} />
      <div className="main">
        <div className="container-fluid px-3 px-lg-5 py-4">
            
            { userData !== null ? 
              <>
              <h1 className="mb-0">Resultados</h1>
              <p>Trabajemos Juntos Muy unidos por Tabasco y la 4T</p>
              <hr />
              <Routes>
                <Route path="/" element={<Index setLoading={setLoading} permisos={userData.tipo_usuario_id} capturista={userData.nombre} PrivateRoute/>} />
                <Route path="/listas" element={userData.tipo_usuario_id === "1" ? 
                  <ListaDifusion setLoading={setLoading} tipoUsuario={userData.tipo_usuario_id} PrivateRoute />
                  : ""} />
                <Route path="/rellenar" element={userData.tipo_usuario_id === "1" ? 
                  <Test setLoading={setLoading} capturista={userData.nombre} PrivateRoute /> : ""} />
                <Route path="/agregar-padron" element={<Padron setLoading={setLoading} capturista={userData.nombre} PrivateRoute />} />
                <Route path="/dashb" element={<DashboardUsuario setLoading={setLoading} capturista={userData.nombre} PrivateRoute />} />
                <Route path="/subir-excel2" element={<Excel2 setLoading={setLoading} capturista={userData.nombre} PrivateRoute />} />
                <Route path="/subir-excel" element={<Excel setLoading={setLoading} capturista={userData.nombre} PrivateRoute />} />
                <Route path="/lista-miembros" element={<Lista setLoading={setLoading} capturista={userData.nombre} tipoUsuario={userData.tipo_usuario_id} PrivateRoute />} />
                <Route path="/logout" element={<Logout logoutHandler={logoutHandler} PrivateRoute />} />
                <Route path="/editar" element={<Editar setLoading={setLoading} capturista={userData.nombre} PrivateRoute />} />
              </Routes> </> : <Routes>
                <Route path="/login" element={<Login setLoading={setLoading} loginHandler={loginHandler} />} />
                <Route path="*" element={<Login setLoading={setLoading} loginHandler={loginHandler} />} />
              </Routes>
            }

            {//<button onClick={()=>{console.log(userData)}}>sadsad</button>
            }
            <Loading loading={loading} />

        </div>
      </div>
      </AuthContext.Provider>
    </>
  );
}

export default App;
