import React, {useState, useEffect, useMemo} from 'react';
import * as XLSX from 'xlsx';
import { Get } from '../Tools/HelperBot';
import StatusBar from '../Tools/StatusBar';
import ExcelImg from '../excel.png';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faFileArrowUp, faEraser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import Select from 'react-select';

function Lista({setLoading}){

	const [status, setStatus] = useState({status: null, message: ""});
	const [municipios, setMunicipios] = useState([]);
	const [dataSecciones, setDataSecciones] = useState([]);
	const [selectedMun, setSelectedMun] = useState([]);
	const [plantilla, setPlantilla] = useState("");
	const [total, setTotal] = useState(0);
	const [data, setData] = useState([]);

	//const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, []);

	useEffect(() => {

		const getLista = async e =>{
    
			setStatus({status: null, message: ""});
			setLoading(true);
			const submit = await Get('municipios');

			if(submit.status.status === "success"){
				setStatus({status: null, message: ""});
				setMunicipios(submit.data);
				const submit2 = await Get('interesados');

				if(submit2.status.status === "success"){
					setStatus({status: null, message: ""});
					setData(submit2.data);
				}else{
					setStatus(submit2.status);
				}
			}else{
				setStatus(submit.status);
			}
			
			setLoading(false);
		}

		getLista();

	}, []);

	const columns = [
		{
	        name: 'ID',
	        selector: "id",
	        grow: 0,
	        sortable: true
	    },
	    {
	        name: 'Nombre',
	        selector: "nombre",
	        grow: 1,
	        sortable: true
	    },
	    {
	        name: 'Municipio',
	        selector: "municipio",
	        wrap: true,
	        grow: 1,
	        sortable: true
	    },
	    {
	        name: 'Seccion',
	        selector: "seccion",
	        wrap: true,
	        grow: 0,
	        sortable: true
	    },
	    {
	        name: 'Clave Elector',
	        selector: "clave",
	        wrap: true,
	        grow: 2,
	        sortable: true
	    },
	    {
	        name: 'Telefono',
	        selector: "user.phone_number",
	        wrap: true,
	        grow: 1,
	        sortable: true
	    },
	];

	const tableData = {
	    columns,
	    data,
	};

	useEffect(()=>{

		const getSecciones = async e =>{
    
			setStatus({status: null, message: ""});
			setLoading(true);
			const submit = await Get('secciones/'+selectedMun.municipio);

			if(submit.status.status === "success"){
				setStatus({status: null, message: ""});
				setDataSecciones(submit.data);
				setTotal(submit.total);
			}else{
				setStatus(submit.status);
			}
			
			setLoading(false);
		}

		if(selectedMun.municipio){
			getSecciones();
		}
		
	},[selectedMun]);

	const badges = dataSecciones.map((seccion, index) => {
		return(<h5 className="d-inline px-1"><span class="badge bg-success" key={index}>{seccion.seccion}</span></h5>);
	});

	const getInteresados = async e =>{
    
		setStatus({status: null, message: ""});
		setLoading(true);
		
		
		setLoading(false);
	}

	return(
   		<div>
	        <div className="row">
	        	<div className="col-12">
					
	        		<h3 className="pb-4">Enviar WhatsApp Masivo</h3>
	        		
	        		<div className="row">
	        			<div className="col-6">
	        				<label htmlFor="template" className="form-label">Municipio<span className="req">*</span></label>
			        		<Select 
			                  options={municipios}
			                  getOptionValue={(option) => `${option['municipio']}`}
			                  getOptionLabel={(option) => `${option['municipio']}`}
			                  onChange={(option)=>setSelectedMun(option)}
			                  value={selectedMun}
			                  placeholder="Seleccione Municipio..."
			                />
			            </div>
			            <div className="col-6">
            				<label htmlFor="template" className="form-label">Plantilla<span className="req">*</span></label>
            				<input type="text" name="template" 
			                  placeholder="Nombre de la plantilla" className="form-control"
			                  value={plantilla} onChange={e => setPlantilla(e.target.value)}
			                />
            			</div>
			        </div>

	                {selectedMun.municipio ? 
	                	<div className="py-3">
	                		<div class="alert alert-warning" role="alert">
								Total de mensajes a enviar: {total}
							</div>
	                		<h5>Secciones del municipio:</h5>
	                		{badges}
	                		<div className="row pt-3">
	                			<div className="col-auto">

	                				<button className="btn btn-primary btn-lg" onClick={()=>console.log("")}>
	                					<FontAwesomeIcon icon="fa-solid fa-cloud-arrow-up" className="pe-2" /> 
	                					Enviar mensaje
	                				</button>
	                				
	                			</div>
	           
	                		</div>
	                	</div> : ""}
				   
			    </div>
			   	<div className="col-12 pt-5">
			   		<h3 className="pb-4">Lista de Interesados en comites</h3>
    				<DataTableExtensions {...tableData}
	        			export={true}
						print
	        			exportHeaders
				        fileName={"Reporte"}
				        filterPlaceholder="Buscar..." >
			        	<DataTable
				            columns={columns}
				            data={data}
				            fixedHeader
				        />  
			        </DataTableExtensions>
			   	</div>
	        </div>
			<StatusBar status={status} />
		</div>
	)
}

export default Lista;