import React, {useState, useEffect, useMemo} from 'react';
import { Post, Get } from '../Tools/Helper';
import StatusBar from '../Tools/StatusBar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

import Select from 'react-select';

function Padron({setLoading, capturista}){

  library.add(faCloudArrowUp);

  const [status, setStatus] = useState({status: null, msg: ""});
  const [selectedData, setSelectedData] = useState({
    distrito_local:'',
    distrito_federal:'',
    municipio:'BALANCAN',
    seccion:'',
  });

  const emptyForm =  { 
    distrito_local:selectedData.distrito_local,
    distrito_federal:selectedData.distrito_federal,
    municipio:selectedData.municipio,
    seccion:selectedData.seccion,
    apellido_paterno: '',
    apellido_materno: '',
    nombre: '',
    calle: '',
    num_ext: '',
    num_int: '',
    colonia: '', 
    estado: 'TABASCO',
    cp: '',
    edad: '',
    clave_elector: '',
    correo_personal: '',
    celular: '',
    facebook: '',
    tipo_vinculo: 'PROMOTOR',
    vencimiento: '2021',
    comite: '01',
    id_lista: 1,
  };

  const [num, setNum] = useState(1);
  const [coordinador, setCoordinador] = useState("");
  const [seccion, setSeccion] = useState(null);

  const [distritoLocal, setDistritoLocal] = useState([]);
  const [distritoFederal, setDistritoFederal] = useState([]);
  const [comites, setComites] = useState(null);
  const [secciones, setSecciones] = useState(null);
  const [municipios, setMunicipios] = useState([]);

  const estados = ["TABASCO"];
  const years = ["2021","2022","2023","2024","2025","2026","2027","2028","2029","2030"];
  const mayus = /[^A-z\s]/;
  const numeric = /[^0-9]/;

  const [formData, setFormData] = useState([emptyForm]);

  useEffect(()=>{
    if(num>=1){
      if(num<formData.length){

        let tempData = [...formData];

        for(let i = formData.length;i>num;i--){
          tempData.pop();
        }

        setFormData(formData => tempData);
      }else{

        for(let i = formData.length;i<num;i++){
          setFormData(formData => [...formData, emptyForm]);
        }
      }
    }
  },[num,emptyForm]);

  useEffect(()=>{

    const getCatalogos = async e =>{
    
      setStatus({status: null, message: ""});
      setLoading(true);
      const catalogos = await Get('catalogos');

      if(catalogos.status.status === "success"){
        setStatus({status: null, message: ""});
        setDistritoLocal(catalogos.local);
        setDistritoFederal(catalogos.federal);
        setComites(catalogos.comites);
        setMunicipios(catalogos.municipios);
        setSecciones(catalogos.secciones);

        let tempData = [...formData];
        tempData[0]["distrito_local"] = catalogos.local[0].num;
        tempData[0]["distrito_federal"] = catalogos.federal[0].num;
        tempData[0]["municipio"] = catalogos.municipios[0].nombre_municipio;
        tempData[0]["seccion"] = catalogos.secciones[0].nombre_municipio;
        setFormData(formData => [...tempData]);
      }else{
        setStatus(catalogos.status);
      }
      setLoading(false);
    }

    getCatalogos();

  },[setLoading]);


  function MapList({list, keyName, value}){
    return list.map((item) =>
    <option key={item[keyName]} value={item[value]}>{item[value]}</option>);
  }

  function SimpleMapList({list}){
    return list.map((item) =>
    <option key={item} value={item}>{item}</option>);
  }

  function MapListSeccion(){
    return secciones.map((item,index) =>
    <option key={index} value={index}>{item.num_seccion}</option>);
  }

  useEffect(()=>{

    const getComite = async e =>{
    
      setStatus({status: null, message: ""});
      setLoading(true);
      const listaComites = await Get('comite/' + seccion.seccion_id);

      if(listaComites.status.status === "success"){
        setStatus({status: null, message: ""});
        setComites(listaComites.data);

        let filteredData = formData.map( function(value) { 
            value['comite'] = listaComites.data[0].num;
            return value; 
        });

        setFormData(filteredData);
      }else{
        setStatus(listaComites.status);
      }
      setLoading(false);
    }

    if(seccion !== null && seccion!=="none"){
      getComite();
    }

  },[seccion, secciones, setLoading]);

  const handleFormChange = (index, event, expression = null) => {
    let tempData = [...formData];
    let val = "";
    expression !== null ? val = event.target.value.replace(expression, '') : val = event.target.value;
    tempData[index][event.target.name] = val.toUpperCase();
    setFormData(tempData);
  }

  const handleChangeTipo = (index, event) =>{
    const selectedIndex = event.target.options.selectedIndex;
    const value = event.target.options[selectedIndex].getAttribute('value');

    let tempData = [...formData];
    tempData[index][event.target.name] = value;
    setFormData(tempData);
  }

  /*
  const handleChangeSeccion = e =>{
    const selectedIndex = e.target.options.selectedIndex;
    const index = e.target.options[selectedIndex].getAttribute('value');

      let filteredData = formData.map( function(value) { 
          value['distrito_local'] = secciones[index].distrito_local;
          value['distrito_federal'] = secciones[index].distrito_federal;
          value['municipio'] = secciones[index].municipio;
          value['seccion'] = secciones[index].num_seccion;
          return value; 
      });

      setFormData(filteredData);

    setSeccion(index);
    
  }

  const handleChangeSeccion3 = e =>{

    const selectedValue = e.target.value;
    const selectedLabel = e.target.label;

      let filteredData = formData.map( function(value) { 
          value['distrito_local'] = secciones[selectedValue].distrito_local;
          value['distrito_federal'] = secciones[selectedValue].distrito_federal;
          value['municipio'] = secciones[selectedValue].municipio;
          value['seccion'] = secciones[selectedValue].num_seccion;
          return value; 
      });

      setFormData(filteredData);

    setSeccion([{seccion_id:selectedValue, num_seccion: selectedLabel}]);
    
  }*/

  const handleChangeSeccion = selectedOption => {

    let filteredData = formData.map( function(value) { 
      value['distrito_local'] = selectedOption.distrito_local;
      value['distrito_federal'] = selectedOption.distrito_federal;
      value['municipio'] = selectedOption.municipio;
      value['seccion'] = selectedOption.num_seccion;
      value['comite'] = "01";
      return value; 
    });

    setSelectedData({
      distrito_local:selectedOption.distrito_local,
      distrito_federal:selectedOption.distrito_federal,
      municipio:selectedOption.municipio,
      seccion:selectedOption.num_seccion,
    });

    setFormData(filteredData);
    setSeccion(selectedOption);
  };

  const submitPadron = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    let filteredData = formData.map( function(value) { 
        value['cot_responsable'] = coordinador;
        value['capturista'] = capturista;
        value['referente'] = capturista;
        value['id_lista'] = 1;
        return value; 
    });

    const submit = await Post('insertar-padron',filteredData);

    if(submit.status.status === "success"){
      setNum(1);
      setFormData([emptyForm]);
    }
    setStatus(submit.status);
    setLoading(false);
  }

  const forms = formData.map((form, index) => {
    return (
      <div className="col-12 py-2" key={index}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className={"col-6 col-lg-2 mb-3"}>
                <label htmlFor="tipo_vinculo" className="form-label">Tipo<span className="req">*</span></label>
                <select name="tipo_vinculo" className="form-select" onChange={event => handleChangeTipo(index,event)} value={form.tipo_vinculo}>
                  <option key={"PROMOTOR"} id={1} value="PROMOTOR">PROMOTOR</option>
                </select>
              </div>
              <div className="col-6 col-lg-2 mb-3">
                <label htmlFor="distrito_federal" className="form-label">Distrito Fed.<span className="req">*</span></label>
                {seccion === null ? <select name="distrito_federal" className="form-select" onChange={event => handleChangeTipo(index,event)} value={form.distrito_federal}>
                                  <MapList keyName="id_distrito_federal" value="num" list={distritoFederal} />
                                </select> : <input type="text" name="id_distrito_federal" className="form-control"
                                value={form.distrito_federal} disabled />}
              </div>
              <div className="col-6 col-lg-2 mb-3">
                <label htmlFor="distrito_local" className="form-label">Distrito Loc.<span className="req">*</span></label>
                {seccion === null ? <select name="distrito_local" className="form-select" onChange={event => handleChangeTipo(index,event)} value={form.distrito_local}>
                                  <MapList keyName="id_distrito_local" value="num" list={distritoLocal} />
                                </select> : <input type="text" name="distrito_local" className="form-control" 
                                value={form.distrito_local} disabled />}
              </div>
              <div className="col-2 col-lg-2 mb-3">
                <label htmlFor="comite" className="form-label">Comite<span className="req">*</span></label>
                  {comites != null ? 
                    <select name="comite" className="form-select" onChange={event => handleChangeTipo(index,event)} value={form.comite}>
                      <MapList keyName="id_comite" value="num" list={comites} />
                    </select> : 
                    <select name="distrito_federal" className="form-select" disabled>
                      <option key="0" value={null}>...</option>
                    </select>}
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-3">
                <label htmlFor="apellido_paterno" className="form-label">Apellido Paterno<span className="req">*</span></label>
                <input type="text" name="apellido_paterno" 
                  placeholder="Apellido Paterno" className="form-control"
                  value={form.apellido_paterno} onChange={event => handleFormChange(index, event, mayus)} />
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-3">
                <label htmlFor="apellido_materno" className="form-label">Apellido Materno<span className="req">*</span></label>
                <input type="text" name="apellido_materno" 
                  placeholder="Apellido Materno" className="form-control"
                  value={form.apellido_materno} onChange={event => handleFormChange(index, event, mayus)} />
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-3">
                <label htmlFor="nombre" className="form-label">Nombre<span className="req">*</span></label>
                <input type="text" name="nombre" 
                  placeholder="Nombre" className="form-control"
                  value={form.nombre} onChange={event => handleFormChange(index, event, mayus)} />
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-3">
                              <label htmlFor="calle" className="form-label">Calle</label>
                              <input type="text" name="calle" 
                                placeholder="Calle" className="form-control"
                                value={form.calle} onChange={event => handleFormChange(index, event)} />
                            </div>
              <div className="col-3 col-lg-1 mb-3">
                              <label htmlFor="num_ext" className="form-label">Num Ext</label>
                              <input type="text" name="num_ext" 
                                placeholder="Ext" className="form-control"
                                value={form.num_ext} onChange={event => handleFormChange(index, event, numeric)}
                                size="5" maxLength="5" />
                            </div>
                            <div className="col-3 col-lg-1 mb-3">
                              <label htmlFor="num_int" className="form-label">Num Int</label>
                              <input type="text" name="num_int" 
                                placeholder="Int" className="form-control"
                                value={form.num_int} onChange={event => handleFormChange(index, event, numeric)}
                                size="5" maxLength="5" />
                            </div>
              <div className="col-6 col-lg-2 mb-3">
                <label htmlFor="colonia" className="form-label">Localidad<span className="req">*</span></label>
                <input type="text" name="colonia" 
                  placeholder="Localidad" className="form-control"
                  value={form.colonia} onChange={event => handleFormChange(index, event)} />
              </div>
              <div className="col-6 col-lg-2 mb-3">
                <label htmlFor="municipio" className="form-label">Municipio<span className="req">*</span></label>
                {seccion === null ? <select name="municipio" className="form-select" onChange={event => handleChangeTipo(index,event)} value={form.municipio}>
                                  <MapList keyName="municipio_id" value="nombre_municipio" list={municipios} />
                                </select> : <input type="text" name="municipio" className="form-control"
                                value={form.municipio} disabled />}
              </div>
              <div className="col-6 col-md-4 col-lg-2 mb-3">
                <label htmlFor="estado" className="form-label">Estado<span className="req">*</span></label>
                <select name="estado" className="form-select" onChange={event => handleChangeTipo(index,event)} value={form.estado}>
                  <SimpleMapList list={estados} />
                </select>
              </div>
              <div className="col-6 col-md-4 col-lg-1 mb-3">
                              <label htmlFor="cp" className="form-label">CP</label>
                              <input type="text" name="cp" 
                                placeholder="CP" className="form-control"
                                value={form.cp} onChange={event => handleFormChange(index, event, numeric)}
                                size="7" maxLength="7" />
                            </div>
              <div className="col-6 col-md-4 col-lg-1 mb-3">
                <label htmlFor="edad" className="form-label">Edad</label>
                <input type="text" name="edad" 
                  placeholder="Edad" className="form-control"
                  value={form.edad} onChange={event => handleFormChange(index, event, numeric)}
                  size="2" maxLength="2" />
              </div>
              <div className="col-lg-3 mb-3">
                <label htmlFor="clave_elector" className="form-label">Clave de elector<span className="req">*</span></label>
                <input type="text" name="clave_elector" 
                  placeholder="Clave Elector" className="form-control"
                  value={form.clave_elector} onChange={event => handleFormChange(index, event)}
                  size="18" maxLength="18" />
              </div>
              <div className="col-6 col-md-4 col-lg-2 mb-3">
                <label htmlFor="vencimiento" className="form-label">Año Vencimiento<span className="req">*</span></label>
                <select name="vencimiento" className="form-select" onChange={event => handleFormChange(index,event,numeric)} value={form.vencimiento}>
                  <SimpleMapList list={years} />
                </select>
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-3">
                              <label htmlFor="correo_personal" className="form-label">Correo</label>
                              <input type="text" name="correo_personal" 
                                placeholder="Correo" className="form-control"
                                value={form.correo_personal} onChange={event => handleFormChange(index, event)} />
                            </div>
              <div className="col-6 col-md-4 col-lg-3 mb-3">
                <label htmlFor="celular" className="form-label">Telefono<span className="req">*</span></label>
                <input type="text" name="celular" 
                  placeholder="Telefono" className="form-control"
                  value={form.celular} onChange={event => handleFormChange(index, event,numeric)}
                  size="10" maxLength="10" />
              </div>
              <div className="col-6 col-md-4 col-lg-3 mb-3">
                              <label htmlFor="facebook" className="form-label">Facebook</label>
                              <input type="text" name="facebook" 
                                placeholder="Facebook" className="form-control"
                                value={form.facebook} onChange={event => handleFormChange(index, event)} />
                            </div>
              <div className="col-6 col-md-4 col-lg-3 mb-3 mt-auto">
                <small className="mt-auto">Los campos marcados con <span className="req">*</span> son <span className="fw-bold">obligatorios</span></small>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  });

	return(
		secciones !== null ? <>
          <div className="row">
            <div className="col-12">
              <h3 className="mb-4"><span className="fw-bold">Agregar</span> datos al padrón</h3>
            </div>
            <div className="col-6 col-lg-4 mb-3">
              <div className="input-group mb-3">
                <span className="input-group-text" id="numero-miembros"># Integrantes:</span>
                <input type="number" className="form-control" aria-label="Numero de miembros a agregar" 
                  aria-describedby="numero-miembros" value={num} onChange={(e)=>{setNum(parseInt(e.target.value))}} />
                <button className="btn btn-outline-secondary" type="button" onClick={()=>{setNum(num + 1)}}>+</button>
                <button className="btn btn-outline-secondary" type="button" onClick={()=>{num > 1 ? setNum(num - 1) : setNum(num)}}>-</button>
              </div>
              <small>Seleccionar primero numero de integrantes, luego la seccion.</small>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="input-group mb-3">
                <span className="input-group-text" id="coord">Referente:</span>
                <input type="text" className="form-control" aria-label="Coordinador" 
                  aria-describedby="coord" value={capturista} disabled />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="input-group mb-3">
                <label className="input-group-text" htmlFor="seccion">Seccion<span className="req">*</span></label>
                {/*
                  <select className="form-select" name="seccion" id="seccion"
                    onChange={event => handleChangeSeccion(event)} value={seccion}>
                    <option key="0" value="">Seleccione...</option>
                    <MapListSeccion />
                  </select>*/
                }
                <Select 
                  options={secciones}
                  getOptionValue={(option) => `${option['seccion_id']}`}
                  getOptionLabel={(option) => `${option['num_seccion']}`}
                  onChange={handleChangeSeccion}
                  value={seccion}
                  placeholder="Seleccione..."
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <StatusBar status={status} />
            </div>
            {forms}
          </div>
          <div className="row pt-2 pb-3">
            <div className="col-12 text-center">
                 <button className="btn btn-primary btn-lg" onClick={submitPadron}><FontAwesomeIcon icon="fa-solid fa-cloud-arrow-up" className="pe-2" /> Subir Miembro{num>1 ?"s":""}</button>
            </div>
          </div>
          {
            //<a className="btn btn-primary btn-lg" onClick={()=>console.log(emptyForm)}><FontAwesomeIcon icon="fa-solid fa-cloud-arrow-up" className="pe-2" /> Sadsadsadsadmbros</a>

              
          }
        </> : "Cargando..." 
	);
}

export default Padron;