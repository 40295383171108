import React from 'react';
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEarthAmericas, faUser, faUsers, faArrowRightFromBracket, faFileArrowUp, faChartColumn, faList, faFilePdf } from '@fortawesome/free-solid-svg-icons';

function Sidebar({data}){

	library.add(faEarthAmericas, faUser, faUsers, faArrowRightFromBracket, faFileArrowUp, faChartColumn, faList, faFilePdf);

	return(
		<div className="collapse sidenav show" id="collapseExample">
			{data ? 
				<>
				<NavLink to="/" className="py-3">
					<FontAwesomeIcon icon="fa-chart-column" className="pe-3" /> Graficas
				</NavLink>
				<NavLink to="/lista-miembros" className="py-3">
				  <FontAwesomeIcon icon="fa-users" className="pe-3" /> Lista de Miembros
				</NavLink>				
				{	data.tipo_usuario_id === "1" ?
					<>
					<NavLink to="/listas" className="py-3">
						<FontAwesomeIcon icon="fa-list" className="pe-3" /> Listas de Difusión
					</NavLink>
					<NavLink to="/rellenar" className="py-3">
						<FontAwesomeIcon icon="fa-file-pdf" className="pe-3" /> Rellenar Formatos
					</NavLink>				
					<NavLink to="/editar" className="py-3">
					<FontAwesomeIcon icon="fa-user" className="pe-3" /> Editar Registro
					</NavLink>
					<NavLink to="/agregar-padron" className="py-3">
						<FontAwesomeIcon icon="fa-user" className="pe-3" /> Agregar Padron
					</NavLink>
					<NavLink to="/subir-excel" className="py-3">
					<FontAwesomeIcon icon="fa-file-arrow-up" className="pe-3" /> Subir Excel
					</NavLink>
					</> : ""
				}
				<hr />
				<NavLink to="/logout" className="py-3">
					<FontAwesomeIcon icon="fa-arrow-right-from-bracket" className="pe-3" /> Cerrar Sesión
				</NavLink> </> :
				<NavLink to="/login" className="py-3">
					<FontAwesomeIcon icon="fa-user" className="pe-3" /> Iniciar Sesión
				</NavLink>
				}
			
			<hr />
			<p className="text-center"></p>
		</div>
	);
}

export default Sidebar;



