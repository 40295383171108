import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Navbar({data}){

	return(
		<nav className="navbar sticky-top navbar-expand-lg navbar-dark bg-dark px-4">
			<a className="navbar-brand" href="#">GESTIÓN ELECTORAL</a>
			<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
				<span className="navbar-toggler-icon"></span>
			</button>
			
			<div className="collapse navbar-collapse justify-content-end" id="navbarNav">
				<ul className="navbar-nav">
					<li className="nav-item active">
					  <FontAwesomeIcon icon="fa-user" className="pe-3" /> {data ? data.nombre : ""}
					</li>
				</ul>
			</div>
		</nav>
	);
}

export default Navbar;


