import React, {useEffect, useState} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale,LinearScale,BarElement} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Post, Get } from '../Tools/Helper';

function Index({setLoading, permisos, capturista}){

	ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,LinearScale,BarElement,ChartDataLabels);

	let planeado = 0;
	let avance = 0;
	let porcentaje = 0;

	const options = {
		responsive: true,
		scales: {
		x: {
			stacked: true
		},
		y: {
			stacked: false,
			ticks: {
				beginAtZero: true,
			},
		},
		},
		plugins: {
			tooltip: {
				enabled: false
			},
			datalabels: {
			  formatter: function(value, context) {
				if(permisos === "1"){
						if(context.dataset.label === "Avance"){
							avance = context.dataset.data[0];
							porcentaje = ( avance / planeado ) * 100;
							return Math.round(porcentaje) + "%";
						}
						else{
							planeado = context.dataset.data[0];
							return context.dataset.data[0];
						}
					}
				else
					return context.dataset.data[0];
			  }
			}
		  }
	};

	const [municipios, setMunicipios] = useState([]);
	const [graficas, setGraficas] = useState([]);
	const [status, setStatus] = useState({status: null, msg: ""});

	const [option, setOption] = useState(0);

	function MapListSeccion(){
	    return municipios.map((item) => {
			if (graficas[item.nombre_municipio].datasets[0].data[0] > 0){
				return (<div className="col-6 py-2">
						<div className="card">
							<div className="card-header">
								<h5 className="mb-0 text-center fw-bold">{item.nombre_municipio}</h5>
							</div>
							<div className="card-body">
								<Pie data={graficas[item.nombre_municipio] } />
							</div>
						</div>
					</div>);
			}
	  	});
	  }


	  function Map2(){
	    return municipios.map((item) => {
			if (graficas[item.nombre_municipio].datasets[0].data[0] > 0){
				return (<div className="col-6 py-2">
						<div className="card">
							<div className="card-header">
								<h5 className="mb-0 text-center fw-bold">{item.nombre_municipio}</h5>
							</div>
							<div className="card-body">
								<Bar options={options} data={graficas[item.nombre_municipio] } />
							</div>
						</div>
					</div>);
			}
	  	});
	  }

	useEffect(()=>{

	  const getCatalogos = async (index) =>{
			setStatus({status: null, message: ""});
			setLoading(true);
			let ruta = "";
			if(permisos === "1"){
				if(index === 1){
					ruta = 'dashboard-comite';
				}else{
					ruta = 'dashboard-web';
				}
			}
			else{
				if(index === 1){
					ruta = 'dash-comite-capturista/' + capturista;
				}else{
					ruta = 'dash-web-capturista/' + capturista;
				}
			}
			const dash = await Get(ruta);
			if(dash.status.status === "success"){
				setMunicipios(dash.municipios);
				setGraficas(dash.graficas);
			}else{
				setStatus(dash.status);
			}

			setLoading(false);
		}

		if(option !== 0){
			getCatalogos(option);
		}

	},[option]);

	return (<>

		<div className="container">
			<div className="row">
				<div className="col-3">
					<button className="btn btn-primary btn-lg w-100"
						onClick={()=>setOption(1)}>Comites</button>
				</div>
				<div className="col-3">
					<button className="btn btn-primary btn-lg w-100"
						onClick={()=>setOption(2)}>Metas</button>
				</div>
				<div className="col-12 pt-4">
					{option === 1 ? <h2>Comites por Municipio</h2> 
						: option === 2 ? <h2>Metas por seccion</h2> 
						: <h2>Seleccione una opción</h2>}
				</div>
				{option === 1 ? Map2() : option === 2 ? MapListSeccion() : ""}
			</div>
			{/*<Pie data={data} />*/}
		</div>
		</>);
}

export default Index;