import React from 'react';

const BotonFirmar = ({filaAEditar,setItem,text,type}) =>{

	var label = '';
	
	if (type===1){
	  label = 'btn btn-success btn-round btn-sm mx-1 px-3';
	}else if(type===2){
	  label = 'btn btn-primary btn-round btn-sm mx-1 px-3';
	}else if(type===3){
	  label = 'btn btn-secondary btn-round btn-sm mx-1 px-3';
	}else if(type===4){
	  label = 'btn btn-danger btn-round btn-sm mx-1 px-3';
	}else if(type===5){
	  label = 'btn btn-warning btn-round btn-sm mx-1 px-3';
	}else{
		label = 'btn btn-success btn-round btn-sm mx-1 px-3';
	}

	return(
		<button type="submit" className={label} onClick={() => setItem(filaAEditar)}>{text}</button>
	);
}

export default BotonFirmar;