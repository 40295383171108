import axios from 'axios';

//const base= 'https://golsbita.golsystems.com/api/';
const base = 'https://gestion-electoral.golsystems.com/bk/public/api/';
//const base= 'http://localhost:8001/api/';
const token = JSON.parse(localStorage.getItem('tokens')) ? JSON.parse(localStorage.getItem('tokens')).access_token : '';



export async function Post(path,obj) {
	const headers = {
		'Authorization': "Bearer " + token,
		'Content-Type': 'application/json' 
	}

	let data = "";

	await axios.post((base + path), obj, {
	    headers: headers
	  })
	  .then((response) => {
		data = response.data;
	  })
	  .catch((error) => {
		console.log(error);
		data = error.response.data;
	  })

	  return data;
}

export async function Get(path) {

	const headers = {
		'Authorization': "Bearer " + token,
		'Content-Type': 'application/json' 
	}

	let data = "";

	await axios.get((base + path), {
	    headers: headers
	  })
	  .then((response) => {
		data = response.data;
	  })
	  .catch((error) => {
	    data = error;
	  })

	  return data;
}

export async function Del(path) {

	const headers = {
		'Authorization': "Bearer " + token,
		'Content-Type': 'application/json' 
	}

	let data = "";

	await axios.delete((base + path), {
	    headers: headers
	  })
	  .then((response) => {
		data = response.data;
	  })
	  .catch((error) => {
	    data = error;
	  })

	  return data;
}

export async function Put(path,obj) {

	const headers = {
		'Authorization': "Bearer " + token,
		'Content-Type': 'application/json' 
	}

	let data = "";

	await axios.put((base + path), obj, {
	    headers: headers
	  })
	  .then((response) => {
		data = response.data;
	  })
	  .catch((error) => {
		data = error.response.data;
	  })

	  return data;
}

export async function PostOld(path,obj){

	let isSubscribed = true;

	try{
		const requestOptions = {
			method: 'POST',
			headers: { 
				'Authorization': "Bearer " + token,
				'Content-Type': 'application/json' 
			},
			body: JSON.stringify(obj)
		};

		let response = await fetch(base+path, requestOptions);

		if (isSubscribed) {
			let data = await response.json();
			return data;
		}

	}catch(err){
		return {status:'error', message: err};
	}

	return () => {isSubscribed = false};

}

export async function GetOld(path){

	let isSubscribed = true;

	try{
		
		const requestOptions = {
			method: 'GET',
			headers: { 
				'Authorization': "Bearer " + token,
				'Content-Type': 'application/json' 
			}
		};

		let response = await fetch(base+path, requestOptions);

		if (isSubscribed) {
			let data = await response.json();
			return data;
		}

	}catch(err){
		return {status:'error', message: err};
	}

	return () => {isSubscribed = false};

}