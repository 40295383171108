import React, {useState, useEffect} from 'react';
import * as XLSX from 'xlsx';
import { Post } from '../Tools/Helper';
import StatusBar from '../Tools/StatusBar';
import ExcelImg from '../excel.png';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faFileArrowUp, faEraser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from "react-router-dom";
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import FileSaver from "file-saver";
import Formato from "../FORMATO-V8.pdf";

function Test({setLoading, capturista}){

	library.add(faDownload,faFileArrowUp,faEraser);

	const [status, setStatus] = useState({status: null, message: ""});
	const [inputKey, setInputKey] = useState();
	const [inputKey2, setInputKey2] = useState();

	const [dataExcel, setDataExcel] = useState("");
	const [arrayBuff, setArrayBuff] = useState();

	const [totalNum, setTotalNum] = useState([]);

	const handleFilePdf = async(event) => {

		setStatus({status: null, message: ""});
		var extension = event.target.files[0].type;
	   	console.log(extension);

		if(extension !== 'application/pdf'){
			setStatus({status: 'danger', message:'La extension del archivo no coincide con un documento PDF.'});
			event.target.value = null;
			return;
		}

		const reader = new FileReader();
		console.log(event.target.files[0]);
		reader.readAsArrayBuffer(event.target.files[0]);
		setLoading(true);

		reader.onloadend = async(e) => {
			setArrayBuff(e.target.result);
			setLoading(false);
		}
	}

	const handleFileExcel = (event) => {

		setStatus({status: null, message: ""});
	   	var extension = event.target.files[0].type;
	   	console.log(extension);

		if(extension !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
			setStatus({status: 'danger', message:'La extension del archivo no coincide con una hoja de calculo Excel.'});
			event.target.value = null;
			return;
		}

		const reader = new FileReader();
		//console.log("1");
		reader.readAsArrayBuffer(event.target.files[0]);
		setLoading(true);

		//console.log("2");
		reader.onloadend = (e) => {
			//console.log("3");
			setLoading(false);
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, {type: 'array', cellDates: 'true'});
			const worksheet = workbook.Sheets[workbook.SheetNames[0]];

			const json = XLSX.utils.sheet_to_json(worksheet,{
                defval: ""
              });
			//console.log(json);
			//let filtered = json.map(({NOMBRE,TELEFONO}) => ({NOMBRE,TELEFONO}));
			setDataExcel(json);

			let x = 0;
			let temp = [];
			while(x < Math.ceil(json.length/100)){
				temp.push(x);
				x++;
			}

			setTotalNum(temp);
		}
	}

	/*
	useEffect(() => { 

	}, [data]);*/

	 function saveByteArray(reportName, byte) {
	    var blob = new Blob([byte], {type: "application/pdf"});
	    var link = document.createElement('a');
	    link.href = window.URL.createObjectURL(blob);
	    var fileName = reportName;
	    link.download = fileName;
	    link.click();
	};

	/* function dataURItoBlob(dataURI) {
	    var byteString = atob(dataURI.split(',')[1]);
	    var ab = new ArrayBuffer(byteString.length);
	    var ia = new Uint8Array(ab);
	    for (var i = 0; i < byteString.length; i++) {
	        ia[i] = byteString.charCodeAt(i);
	    }
	    var bb = new Blob([ab]);
	    return ia;
	} */

	const addPagez = async (number,helveticaFont,data, pdf) =>{
		
		let firstPage = pdf.getPage(number);
		//DISTRITO 1
		firstPage.drawText(data.distrito_federal.charAt(0), {
		  x: 73,
		  y: 556,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		//DISTRITO 2
		firstPage.drawText(data.distrito_federal.charAt(1), {
		  x: 101,
		  y: 556,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		//SECCION 1
		firstPage.drawText(data.seccion_electoral.charAt(0), {
		  x: 171,
		  y: 556,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		//SECCION 2
		firstPage.drawText(data.seccion_electoral.charAt(1), {
		  x: 197,
		  y: 556,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		//SECCION 3
		firstPage.drawText(data.seccion_electoral.charAt(2), {
		  x: 223,
		  y: 556,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		//SECCION 4
		firstPage.drawText(data.seccion_electoral.charAt(3), {
		  x: 249,
		  y: 556,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		if(data.tipo === "A"){
			//AFILIACION
			firstPage.drawEllipse({
			  x: 338,
			  y: 556,
			  xScale: 36,
			  yScale: 20,
			  borderWidth: 5,
			  borderColor: rgb(0,0,0),
			  borderOpacity: 0.75,
			})
		}else{
			//RATIFICACION
			firstPage.drawEllipse({
			  x: 438,
			  y: 552,
			  xScale: 54,
			  yScale: 24,
			  borderWidth: 5,
			  borderColor: rgb(0,0,0),
			  borderOpacity: 0.75,
			})
		}
		
		// FECHA
		firstPage.drawText(data.fecha, {
		  x: 590,
		  y: 552,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// NOMBRE
		firstPage.drawText(data.nombre, {
		  x: 50,
		  y: 480,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// APELLIDO 1
		firstPage.drawText(data.primer_apellido, {
		  x: 294,
		  y: 480,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// APELLIDO 2
		firstPage.drawText(data.segundo_apellido, {
		  x: 524,
		  y: 480,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CALLE
		firstPage.drawText(data.calle, {
		  x: 50,
		  y: 427,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// NUM EXT
		firstPage.drawText(data.num_ext, {
		  x: 555,
		  y: 427,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// NUM INT
		firstPage.drawText(data.num_int, {
		  x: 675,
		  y: 427,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// COLONIA
		firstPage.drawText(data.colonia, {
		  x: 50,
		  y: 374,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// ENTIDAD
		firstPage.drawText(data.entidad, {
		  x: 284,
		  y: 374,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// MUNICIPIO
		firstPage.drawText(data.municipio, {
		  x: 465,
		  y: 374,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CP 1
		firstPage.drawText(data.cp.charAt(0), {
		  x: 650,
		  y: 374,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CP 2
		firstPage.drawText(data.cp.charAt(1), {
		  x: 671,
		  y: 374,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CP 3
		firstPage.drawText(data.cp.charAt(2), {
		  x: 692,
		  y: 374,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CP 4
		firstPage.drawText(data.cp.charAt(3), {
		  x: 713,
		  y: 374,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CP 5
		firstPage.drawText(data.cp.charAt(4), {
		  x: 734,
		  y: 374,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CORREO
		firstPage.drawText(data.correo, {
		  x: 50,
		  y: 321,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// TELEFONO 1
		firstPage.drawText(data.telefono.charAt(0), {
		  x: 509,
		  y: 338,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// TELEFONO 2
		firstPage.drawText(data.telefono.charAt(1), {
		  x: 533,
		  y: 338,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// TELEFONO 3
		firstPage.drawText(data.telefono.charAt(2), {
		  x: 558,
		  y: 338,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// TELEFONO 4
		firstPage.drawText(data.telefono.charAt(3), {
		  x: 583,
		  y: 338,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// TELEFONO 5
		firstPage.drawText(data.telefono.charAt(4), {
		  x: 608,
		  y: 338,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// TELEFONO 6
		firstPage.drawText(data.telefono.charAt(5), {
		  x: 633,
		  y: 338,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// TELEFONO 7
		firstPage.drawText(data.telefono.charAt(6), {
		  x: 658,
		  y: 338,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// TELEFONO 8
		firstPage.drawText(data.telefono.charAt(7), {
		  x: 683,
		  y: 338,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// TELEFONO 9
		firstPage.drawText(data.telefono.charAt(8), {
		  x: 708,
		  y: 338,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// TELEFONO 10
		firstPage.drawText(data.telefono.charAt(9), {
		  x: 733,
		  y: 338,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CEL 1
		firstPage.drawText(data.celular.charAt(0), {
		  x: 509,
		  y: 311,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CEL 2
		firstPage.drawText(data.celular.charAt(1), {
		  x: 533,
		  y: 311,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CEL 3
		firstPage.drawText(data.celular.charAt(2), {
		  x: 558,
		  y: 311,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CEL 4
		firstPage.drawText(data.celular.charAt(3), {
		  x: 583,
		  y: 311,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CEL 5
		firstPage.drawText(data.celular.charAt(4), {
		  x: 608,
		  y: 311,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CEL 6
		firstPage.drawText(data.celular.charAt(5), {
		  x: 633,
		  y: 311,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CEL 7
		firstPage.drawText(data.celular.charAt(6), {
		  x: 658,
		  y: 311,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CEL 8
		firstPage.drawText(data.celular.charAt(7), {
		  x: 683,
		  y: 311,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CEL 9
		firstPage.drawText(data.celular.charAt(8), {
		  x: 708,
		  y: 311,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		// CEL 10
		firstPage.drawText(data.celular.charAt(9), {
		  x: 733,
		  y: 311,
		  size: 12,
		  font: helveticaFont,
		  color: rgb(0,0,0),
		})

		if("clave_elector" in data){
			// CLAVE ELECTOR 1
			firstPage.drawText(data.clave_elector.charAt(0), {
			  x: 49,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 2
			firstPage.drawText(data.clave_elector.charAt(1), {
			  x: 69,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 3
			firstPage.drawText(data.clave_elector.charAt(2), {
			  x: 89,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 4
			firstPage.drawText(data.clave_elector.charAt(3), {
			  x: 109,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 5
			firstPage.drawText(data.clave_elector.charAt(4), {
			  x: 129,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 6
			firstPage.drawText(data.clave_elector.charAt(5), {
			  x: 148,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 7
			firstPage.drawText(data.clave_elector.charAt(6), {
			  x: 166,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 8
			firstPage.drawText(data.clave_elector.charAt(7), {
			  x: 186,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 9
			firstPage.drawText(data.clave_elector.charAt(8), {
			  x: 204,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 10
			firstPage.drawText(data.clave_elector.charAt(9), {
			  x: 223,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 11
			firstPage.drawText(data.clave_elector.charAt(10), {
			  x: 242,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 12
			firstPage.drawText(data.clave_elector.charAt(11), {
			  x: 261,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 13
			firstPage.drawText(data.clave_elector.charAt(12), {
			  x: 279,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 14
			firstPage.drawText(data.clave_elector.charAt(13), {
			  x: 298,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 15
			firstPage.drawText(data.clave_elector.charAt(14), {
			  x: 317,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 16
			firstPage.drawText(data.clave_elector.charAt(15), {
			  x: 336,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 17
			firstPage.drawText(data.clave_elector.charAt(16), {
			  x: 354,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})

			// CLAVE ELECTOR 18
			firstPage.drawText(data.clave_elector.charAt(17), {
			  x: 373,
			  y: 262,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
		}else{// CURP 1
			firstPage.drawText(data.curp.charAt(0), {
			  x: 48,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 2
			firstPage.drawText(data.curp.charAt(1), {
			  x: 68,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 3
			firstPage.drawText(data.curp.charAt(2), {
			  x: 89,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 4
			firstPage.drawText(data.curp.charAt(3), {
			  x: 109,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 5
			firstPage.drawText(data.curp.charAt(4), {
			  x: 128,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 6
			firstPage.drawText(data.curp.charAt(5), {
			  x: 148,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 7
			firstPage.drawText(data.curp.charAt(6), {
			  x: 167,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 8
			firstPage.drawText(data.curp.charAt(7), {
			  x: 186,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 9
			firstPage.drawText(data.curp.charAt(8), {
			  x: 205,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 10
			firstPage.drawText(data.curp.charAt(9), {
			  x: 224,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 11
			firstPage.drawText(data.curp.charAt(10), {
			  x: 243,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 12
			firstPage.drawText(data.curp.charAt(11), {
			  x: 262,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 13
			firstPage.drawText(data.curp.charAt(12), {
			  x: 281,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 14
			firstPage.drawText(data.curp.charAt(13), {
			  x: 300,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 15
			firstPage.drawText(data.curp.charAt(14), {
			  x: 319,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 16
			firstPage.drawText(data.curp.charAt(15), {
			  x: 338,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 17
			firstPage.drawText(data.curp.charAt(16), {
			  x: 356,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
	
			// CURP 18
			firstPage.drawText(data.curp.charAt(17), {
			  x: 375,
			  y: 207,
			  size: 12,
			  font: helveticaFont,
			  color: rgb(0,0,0),
			})
		}

		
  	}

  	const add100Pages = async(x) =>{
  		// const pdfDoc1 = await PDFDocument.create();

  		const data = new Uint8Array(arrayBuff);
	 	const pdf = await PDFDocument.load(data);

		let helveticaFont = await pdf.embedFont(StandardFonts.Helvetica);
		let pages = pdf.getPages();

  		const start = x === 0 ? 0 : (x * 100);
  		const end = ((100 * x) + 100)-1 > (dataExcel.length-1) ? (dataExcel.length - 1) : ((100 * x) + 100)-1;

  		for (let i = start; i < end-1; i++) {
  			const [existingPage] = await pdf.copyPages(pdf, [0])
			pdf.insertPage(0, existingPage)
  		}
  		
  		for (let i = start; i < end; i++) {
  			addPagez(i, helveticaFont, dataExcel[i], pdf);
  		}

  		const pdfBytes = await pdf.save()
		saveByteArray("Registro " + (start === 0 ? 1 : start) + " al " + end, pdfBytes);

  	}


  	const botonPdf = (x) => {

  		const start = x === 0 ? 1 : (x * 100);
  		const end = ((100 * x) + 100)-1 > (dataExcel.length-1) ? (dataExcel.length - 1) : ((100 * x) + 100)-1;

  		return(
  			<div className="col-3 pt-4">
  				<button onClick={()=>add100Pages(x)} className="btn btn-success w-100">
  					<FontAwesomeIcon icon="fa-download" className="pe-3" />Descargar {start} al {end}
  				</button>
  			 </div>
  			)
  	}

  	const botones = arrayBuff === null ? "" : totalNum.map((number) => {
	   return botonPdf(number);
	});

	function clear1() {
		let randomString = Math.random().toString(10);
		setInputKey(randomString);
		setStatus({status: null, message: ""});
		setArrayBuff(null);
	}

	function clear2() {
		let randomString = Math.random().toString(10);
		setInputKey2(randomString);
		setStatus({status: null, message: ""});
		setDataExcel(null);
		setTotalNum([])
	}
	
	
	return(
   		<div>
	      <div className="row">
	      	<div className="col-12 col-lg-6 pb-4">
		    	<h3 className="py-3"><span className="fw-bold">Bajar</span> formato de integrantes</h3>
		        <div className="card">
	            <div className="card-body">
		            <div className="row">
						<div className="col-3">
							<img src={ExcelImg} className="img-fluid"/>
						</div>
						<div className="col-9 align-self-center">
							<p>Descarga el formato de Excel para subir miembros al padrón.</p>
							<Link to="/FORMATO_AFILIA.xlsx" target="_blank" download className="btn btn-success w-50 mx-auto d-block "><FontAwesomeIcon icon="fa-download" className="pe-3" />Descargar </Link>
							{//<button onClick={logJson} className="btn btn-success w-50 mx-auto d-block ">sadsadsa</button>
							}
						</div>
				    </div>
	          	</div>
	        	</div>
		   	</div>
		   	<div className="col-12 col-lg-6">
			</div>
        	<div className="col-12 col-lg-6">
						<h3 className="py-1"><span className="fw-bold">Plantilla</span> a rellenar (PDF)</h3>
		        <div className="card">
		            <div className="card-body">
			            <div className="mb-3">
							<label htmlFor="formFile" className="form-label">Seleccione el archivo PDF de plantilla para el registro de integrantes.</label>
							<input className="form-control" type="file" id="formFile" onChange={handleFilePdf}
								key={inputKey || '' } />
							
						</div>
						<div className="row">
							<div className="col-12">
								<button onClick={clear1} className="btn btn-danger w-100"><FontAwesomeIcon icon="fa-eraser" className="pe-3" />Limpiar</button>
							</div>
						</div>
		            </div>
		        </div>
			</div>
			<div className="col-12 col-lg-6">
						<h3 className="py-1"><span className="fw-bold">Subir</span> lista de integrantes (EXCEL)</h3>
		        <div className="card">
		            <div className="card-body">
			            <div className="mb-3">
							<label htmlFor="formFile" className="form-label">Seleccione el archivo de excel con los datos de los miembros a agregar</label>
							<input className="form-control" type="file" id="formFile" onChange={handleFileExcel}
								key={inputKey2 || '' } />
							{ dataExcel !== null ? <p className="pt-3">El archivo seleccionado contiene <span className="fw-bold">{dataExcel.length}</span> registros de miembros para añadir.</p>: "" }
						</div>
						<div className="row">
							<div className="col-12">
								<button onClick={clear2} className="btn btn-danger w-100"><FontAwesomeIcon icon="fa-eraser" className="pe-3" />Limpiar</button>
							</div>
						</div>
		            </div>
		        </div>
			</div>
			{/*<div className="col-12 pt-4">
							<button onClick={()=>add100Pages(0)} className="btn btn-primary mx-auto px-5 py-3 d-block"><FontAwesomeIcon icon="fa-file-arrow-up" className="pe-3" />Generar PDF's</button>
						</div>*/}
		   	<div className="col-12 pt-4">
		   		<StatusBar status={status} />
		   	</div>
		   	{
				status.status === "danger" ? "" : botones
			}
		      </div>
				</div>
	)

	// This should be a Uint8Array or ArrayBuffer
	// This data can be obtained in a number of different ways
	// If your running in a Node environment, you could use fs.readFile()
	// In the browser, you could make a fetch() call and use res.arrayBuffer()

	// Load a PDFDocument from the existing PDF bytes


}

export default Test;