import React, {useState, useEffect, useMemo } from 'react';
import { Post, Put } from '../Tools/Helper';
import StatusBar from '../Tools/StatusBar';

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import ModalRegistro from './EditarRegistros/RegistroModal';
import ModalMultiRegistro from './EditarRegistros/RegistroMultiModal';
import BotonFirmar from '../Tools/BotonFirmar';
import { FormCheck } from 'react-bootstrap';


function Lista({setLoading, capturista, tipoUsuario}){

	const [status, setStatus] = useState({status: null, message: ""});
	const [data, setData] = useState(null);
	const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(false);
	const isAdmin = tipoUsuario == 1 ? true : false;
	const [filaEditar, setFilaEditar] = useState([]);
	const [filasAEditar, setFilasAEditar] = useState([]);

	//const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, []);

	const setDatosEditar = (row) => {
		setFilaEditar(row);
		setShow1(!show1);
	}

	const setDatosMultiEditar = (row) => {
		setFilasAEditar(row);
		setShow2(!show2);
	}

	const handleChange = (row) => {
		filasAEditar.indexOf(row) === -1 ? filasAEditar.push(row) : filasAEditar.splice(filasAEditar.indexOf(row),1);
	}

	function ModalEditarRegistro() {
		return (
		  <ModalRegistro show={show1} onHide={handleClose1} filaEditar={filaEditar} onGuardar={guardarRegistro} />
		);
	  }

	  function ModalMultiEditarRegistro() {
		return (
		  <ModalMultiRegistro show={show2} onHide={handleClose2} filasAEditar={filasAEditar} onGuardar={guardarMultiRegistro} />
		);
	  }

		const guardarRegistro = async e => {
			filaEditar.id_lista = 1;
			setLoading(true);
			const submit = await Put('padron-editar/'+filaEditar.padron_id,filaEditar);
			setLoading(false);
		}

		const guardarMultiRegistro = async e => {
			setLoading(true);
			const submit = await Post('multi-padron-editar/',filasAEditar);
			setLoading(false);
		}


	const handleClose1 = () => setShow1(false);
	const handleClose2 = () => setShow2(false);
	const handleShow1 = () => setShow1(true);
	const handleShow2 = () => setShow2(true);

	useEffect(() => { 

		const getLista = async e =>{
    
			setStatus({status: null, message: ""});
			setLoading(true);
			const submit = await Post('lista-padron',{capturista:capturista});
			if(submit.status.status === "success"){
				setStatus({status: null, message: ""});
				setData(submit.datos);
			}else{
				setStatus(submit.status);
			}
			
			setLoading(false);
		}

		getLista();

	}, []);

	const columns = [
		{
			button: false,
			cell: row =>
			<>
				<FormCheck onClick={() => handleChange(row)} />
			</>,
			width: "10px"
		},	
		{
			name: "Editar",
			button: true,
			cell: row =>
			<>
				<BotonFirmar filaAEditar={row} setItem={setDatosEditar} text="EDITAR" type={"2"} />
			</>
		},			
		{
	        name: 'ID',
	        selector: row => row.padron_id,
			cellExport: row => row.padron_id,
	        grow: 1,
	        sortable: true
	    },
	    {
	        name: 'Tipo',
	        selector: row => row.tipo_vinculo,
			cellExport: row => row.tipo_vinculo,
	        grow: 1,
	        sortable: true
	    },
	    {
	        name: 'Distrito Federal',
	        selector: row => row.distrito_federal,
			cellExport: row => row.distrito_federal,
	        wrap: true,
	        grow: 0,
	        sortable: true
	    },
	    {
	        name: 'Distrito Local',
	        selector: row => row.distrito_local,
			cellExport: row => row.distrito_local,
	        wrap: true,
	        grow: 0,
	        sortable: true
	    },
	    {
	        name: 'Seccion',
	        selector: row => row.seccion,
			cellExport: row => row.seccion,
	        wrap: true,
	        grow: 0,
	        sortable: true
	    },
	    {
	        name: 'Comite',
	        selector: row => row.comite,
			cellExport: row => row.comite,
	        wrap: true,
	        grow: 0,
	    },
	    {
	        name: 'Referente',
	        selector: row => row.referente,
			cellExport: row => row.referente,
	        wrap: true,
	        grow: 0,
	    },
	    {
	        name: 'Nombre',
	        selector: row => row.nombre,
			cellExport: row => row.nombre,
	        wrap: true,
	        grow: 1,
	    },
	    {
	        name: 'Apellido Paterno',
	        selector: row => row.apellido_paterno,
			cellExport: row => row.apellido_paterno,
	        wrap: true,
	        grow: 1,
	    },
	    {
	        name: 'Apellido Materno',
	        selector: row => row.apellido_materno,
			cellExport: row => row.apellido_materno,
	        wrap: true,
	        grow: 1,
	    },
	    {
	        name: 'Calle',
	        selector: row => row.calle,
			cellExport: row => row.calle,
	        wrap: true,
	        grow: 1.5,
	    },
	    {
	        name: '#Ext',
	        selector: row => row.num_ext,
			cellExport: row => row.num_ext,
	        wrap: true,
	        grow: 0,
	    },
	    {
	        name: '#Int',
	        selector: row => row.num_int,
			cellExport: row => row.num_int,
	        wrap: true,
	        grow: 0,
	    },
	    {
	        name: 'Localidad',
	        selector: row => row.colonia,
			cellExport: row => row.colonia,
	        wrap: true,
	        grow: 2,
	    },
	    {
	        name: 'Municipio',
	        selector: row => row.municipio,
			cellExport: row => row.municipio,
	        wrap: true,
	        grow: 0,
	    },
	    {
	        name: 'Estado',
	        selector: row => row.estado,
			cellExport: row => row.estado,
	        wrap: true,
	        grow: 0,
	    },
	    {
	        name: 'CP',
	        selector: row => row.cp,
			cellExport: row => row.cp,
	        wrap: true,
	        grow: 0,
	    },
	    {
	        name: 'Clave de elector',
	        selector: row => row.clave_elector,
			cellExport: row => row.clave_elector,
	        wrap: true,
	        grow: 0,
	    },
	    {
	        name: 'Edad',
	        selector: row => row.edad,
			cellExport: row => row.edad,
	        wrap: true,
	        grow: 0,
	    },
	    {
	        name: 'Correo',
	        selector: row => row.correo_personal,
			cellExport: row => row.correo_personal,
	        wrap: true,
	        grow: 2,
	    },
	    {
	        name: 'Telefono',
	        selector: row => row.celular,
			cellExport: row => row.celular,
	        wrap: true,
	        grow: 2,
	    },
	    {
	        name: 'Facebook',
	        selector: row => row.facebook,
			cellExport: row => row.facebook,
	        wrap: true,
	        grow: 1,
	    },
	    {
	        name: 'Año Vencimiento',
	        selector: row => row.vencimiento,
			cellExport: row => row.vencimiento,
	        wrap: true,
	        grow: 1,
	    },
	];

	const conditionalRowStyles = [
		{
			when: row => row.tipo_vinculo === "PROMOTOR",
			style: {
				backgroundColor: '#faebf8',
			},
		},
		{
			when: row => row.tipo_vinculo === "CONVENCIDO",
			style: {
				backgroundColor: '#e8f7e6',
			},
		},
	];

	const tableData = {
	    columns,
	    data,
	};	

	return(
   		<><div>
	        <div className="row">
	        	<div className="col-12">
					
			        {
			        	data !== null ? 
			        		<>
			        		<h3 className="pb-4">Lista de miembros capturados por: <span className="fw-bold">{capturista}</span></h3><BotonFirmar filaAEditar={filasAEditar} setItem={setDatosMultiEditar} text="EDITAR SELECCIONADOS" type={"2"} />
			        		<div className="table-border">
				        		<DataTableExtensions {...tableData}
				        			export={isAdmin}
				        			print={isAdmin}
				        			exportHeaders
							        fileName={"Reporte"}
							        filterPlaceholder="Buscar..." >
						        	<DataTable
							            columns={columns}
							            data={data}
							            conditionalRowStyles={conditionalRowStyles}
							            fixedHeader
							            pagination
							        />  
						        </DataTableExtensions>	
								<ModalEditarRegistro />
								<ModalMultiEditarRegistro />
					        </div></>: ""
							
				    }
			    </div>	         	
	        </div>
	       
			<StatusBar status={status} />
		</div>
		</>
	)
}

export default Lista;