import React, {useState} from 'react';
import { Post } from '../Tools/Helper';
import StatusBar from '../Tools/StatusBar';
import { useNavigate  } from "react-router-dom";

export default function Login({logoutHandler}) {

  return(
  	<>
  	{logoutHandler()}
  	</>
  )
}