// src/components/bootstrap-carousel.component.js
import React, { useState } from "react";

import { Modal, Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.min.css';

const RegistroModal = ({show, onHide, filasAEditar, onGuardar}) => {
	
    const [seccion, setSeccion] = useState(filasAEditar);
	const [comite, setComite] = useState(filasAEditar);

    function handleChange2(e){
		setComite(e.target.value);
		filasAEditar.forEach((el)=>{
			el.comite = e.target.value;
			el.id_lista = 1;
		})
	}

	function handleChange1(e){
		setSeccion(e.target.value);
		filasAEditar.forEach((el)=>{
			el.seccion = e.target.value;
			el.id_lista = 1;
		})
	}
        return (
            show ? 
        <Modal
		    show={show}
            onHide={onHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		  >
			<Modal.Header closeButton>
			  <Modal.Title id="contained-modal-title-vcenter">
				Modificación Múltiple de Sección
			  </Modal.Title>
			</Modal.Header>
			<Modal.Body className="show-grid">
				<Container>
					<Row>
						<Col sm={6}>
						<label htmlFor='seccion' className='form-label'>Sección</label>
						<input name='seccion' className='form-control' type='number' defaultValue={seccion} onChange={handleChange1}/>
						</Col>
						<Col sm={6}>
						<label htmlFor='comite' className='form-label'>Comité</label>
						<input name='comite' className='form-control' type='number' value={comite} onChange={handleChange2} />
						</Col>
					</Row>
				</Container>
			</Modal.Body>
			<Modal.Footer>
                <button onClick={onGuardar} className='btn btn-primary btn-round btn-sm mx-1 px-3'>Guardar</button>
			  	<button onClick={onHide} className='btn btn-warning btn-round btn-sm mx-1 px-3'>Cerrar</button>
			</Modal.Footer>
		  </Modal> : ''
        )
}

export default RegistroModal;